import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from './Box'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { space } from 'styled-system'

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 40px;
  border-radius: 2px;
  border: solid 1px ${props => props.theme.color.greyShade2};
  background-color: ${props => props.theme.color.brandPrimaryTint8};
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  padding: 12px;

  &:focus {
    box-shadow: ${props => props.theme.color.boxShadow};
    border: solid 1px ${props => props.theme.color.brandPrimary};
    background-color: ${props => props.theme.color.brandPrimaryTint8};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.color.greyShade2};
    border-color: ${props => props.theme.color.greyShade3};
  }

  &::placeholder {
    opacity: 1;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.color.greyShade3};
  }
`
const Hint = styled.div`
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.color.greyShade5};
  display: block;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 4px;
  transition: border 0.5s;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`

const Label = styled.label`
  font-size: 12px;
  margin: 0 0 4px;
  color: ${props => props.theme.color.greyShade5};
`

export const Textarea = ({ id, label, hint, ...rest }) => (
  <Box {...pick(rest, [...Object.keys(space)])}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <StyledTextarea id={id} {...omit(rest, [...Object.keys(space)])} />
    {hint && <Hint>{hint}</Hint>}
  </Box>
)

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.node
}
export default Textarea
